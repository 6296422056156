<template>
  <div>
    <x-table
      title="固件升级"
      :card="true"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
      <template slot="form-createTime" slot-scope="scope">
        <div>{{ new Date(scope.data).cxFormat('yyyy-MM-dd hh:mm')  }} </div>
      </template>
      <template slot="form-sign">
       <b-form-input
        :value="signValue"
        placeholder="请输入签名"
        readonly
       />
      </template>
      <template slot="createTime" slot-scope="scope">
        <div>{{ new Date(scope.row.createTime).cxFormat('yyyy-MM-dd hh:mm:ss')  }} </div>
      </template>
    </x-table>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BFormInput,
} from 'bootstrap-vue'
import {
  query, add, edit, delById,
} from '@/api/ops/firmware-upgrade'
import { getToken } from '@/@core/auth/token'
import crypto from 'crypto'

export default {
  components: {
    XTable,
    BFormInput,
  },
  data() {
    return {
      file: undefined,
      signValue: undefined,
      signType: undefined,
      productName: undefined,
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        cancelOnOutside: true,
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon', url: '/ops/firmware/view/{id}' },
          { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, name: '删除', icon: 'Trash2Icon' },
        ],
        actionFilter: action => action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.EDIT || action === 'delete',
        columns: [{
          label: '固件名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入固件名称',
          },
        }, {
          label: '固件版本',
          labelShow: true,
          prop: 'version',
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入版本号',
          },
        }, {
          label: '版本序号',
          labelShow: true,
          prop: 'versionOrder',
          rowShow: false,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入版本序号',
          },
        }, {
          label: '文件上传',
          labelShow: true,
          rowShow: false,
          prop: 'url',
          type: 'upload',
          searchShow: false,
          upload: {
            url: this.$xapi.UPLOAD,
            headers: {
              'X-Access-Token': getToken(),
            },
            limit: 1,
            onSuccess: (response, form, file) => {
              this.file = file.raw
              this.signPackageFile(form)
            },
            tip: '文件大小不超过500kb',
          },
          rules: {
            rule: 'required',
            message: '请上传文件',
          },
        }, {
          label: '所属产品',
          labelShow: true,
          type: 'select',
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          dictUrl: 'device-product/_query/no-paging',
          onDictSelected: (option, column, formData) => {
            column[4].dictData.forEach(item => {
              if (item.value === option) {
                formData.productName = item.label
              }
            })
          },
          getDictLabel: option => option.label + `（${option.value})`,
          props: { label: 'name', value: 'id' },
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请选择所属产品',
          },
        }, {
          label: '签名方式',
          labelShow: true,
          prop: 'signMethod',
          searchShow: false,
          editDisable: true,
          type: 'select',
          dictData: [
            {
              id: 'MD5',
              name: 'MD5',
            },
            {
              id: 'SHA256',
              name: 'SHA256',
            },
          ],
          onDictSelected: (option, column, formData) => {
            this.signType = option
            this.signPackageFile(formData)
          },
          props: { label: 'name', value: 'id' },
          rules: {
            rule: 'required',
            message: '请选择签名方式',
          },
        }, {
          label: '签名',
          labelShow: true,
          prop: 'sign',
          rowShow: false,
          addDisable: true,
          editDisable: true,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入签名',
          },
        }, {
          label: '创建时间',
          labelShow: true,
          addShow: false,
          editShow: false,
          prop: 'createTime',
          type: 'datetime',
          format: 'yyyy-MM-dd hh:mm',
          tag: true,
          props: { label: 'label2', value: 'value2' },
          searchShow: false,
        }, {
          label: '描述',
          labelShow: true,
          rowShow: false,
          prop: 'description',
          type: 'textarea',
          rows: 5,
          searchShow: false,
        },
        ],
      },
      productList: [],
    }
  },
  created() {
  },
  methods: {
    beforeShow(type, formData) {
      this.signValue = formData.sign
    },
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      delById(data.id).then(() => {
        done()
      })
    },
    addHandle(formData, done) {
      // formData.url = formData.url[0].url
      add(formData).then(() => {
        done()
      })
    },
    sign(binary, alg) {
      let md5 = crypto.createHash(alg)
      md5.update(binary, 'binary')
      return md5.digest('hex')
    },
    signPackageFile(form) {
      if (this.file && this.signType) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(this.file)
        form.size = this.file.size
        fileReader.onload = e => {
          form.sign = this.sign(e.target.result, this.signType.toLowerCase())
          this.signValue = form.sign
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
