import request from '@/auth/jwt/useJwt'

/*  分页查询组件列表  */
export function query(pageIndex, pageSize, params) {
  let requestUrl = `firmware/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params !== null) {
    if (params.name !== undefined && params.name !== '') {
      requestUrl = `firmware/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=name&terms[0].value=${params.name}`
    }
    if (params.productId !== undefined && params.productId !== null) {
      requestUrl = `firmware/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=productId&terms[0].value=${params.productId}`
    }
    if (params.name !== undefined && params.productId !== undefined && params.name !== '' && params.productId !== null) {
      requestUrl = `firmware/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=name&terms[0].value=${params.name}&terms[1].column=productId&terms[1].value=${params.productId}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/*  新增固件版本  */
export function add(data) {
  return request.axiosIns({
    url: 'firmware/',
    method: 'PATCH',
    data,
  })
}
/* 编辑固件版本 */
export function edit(data) {
  return request.axiosIns({
    url: 'firmware/',
    method: 'PATCH',
    data,
  })
}
/* 删除证书 */
export function delById(id) {
  return request.axiosIns({
    url: `firmware/${id}`,
    method: 'DELETE',
  })
}
/* 获取固件信息 */
export function getById(id) {
  return request.axiosIns({
    url: `firmware/${id}`,
    method: 'get',
  })
}
/* 升级任务列表 */
export function getTaskList(pageIndex, pageSize, params) {
  return request.axiosIns({
    url: `firmware/upgrade/task/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms%5B0%5D.column=firmwareId&terms%5B0%5D.value=${params.id}`,
    method: 'get',
  })
}
/* 删除升级任务 */
export function removeTask(id) {
  return request.axiosIns({
    url: `firmware/upgrade/task/${id}`,
    method: 'DELETE',
  })
}
/* 升级记录列表 */
export function getUpgradeHistoryList(pageIndex, pageSize, params) {
  let requestUrl = `firmware/upgrade/history/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms%5B0%5D.column=firmwareId&terms%5B0%5D.value=${params.id}`
  let index = 1
  if (params.deviceName) {
    requestUrl = `firmware/upgrade/history/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms%5B0%5D.column=firmwareId&terms%5B0%5D.value=${params.id}&terms[${index}].column=deviceName&terms[${index}].value=${params.deviceName}`
    index++
  }
  if (params.state) {
    requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/* 无分页查询产品列表 */
export function getProducts() {
  return request.axiosIns({
    url: 'device-product/_query/no-paging',
    method: 'get',
  })
}
/*  查询产品信息  */
export function getProductById(id) {
  return request.axiosIns({
    url: `/device/product/${id}`,
    method: 'GET',
  })
}
// 获取设备列表
export function getDeviceList(pageNo, productId) {
  return request.axiosIns({
    url: `device-instance/_query?pageIndex=${pageNo}&pageSize=8&terms%5B0%5D.column=productId&terms%5B0%5D.value=${productId}`,
    method: 'GET',
  })
}
// 查询任务的设备数量
export function getStateCount(taskId, state) {
  return request.axiosIns({
    url: `firmware/upgrade/history/_count?terms%5B0%5D.column=taskId&terms%5B0%5D.value=${taskId}&terms%5B1%5D.column=state&terms%5B1%5D.value=${state}`,
    method: 'GET',
  })
}
// 升级任务提交
export function patch(data) {
  return request.axiosIns({
    url: 'firmware/upgrade/task',
    method: 'PATCH',
    data,
  })
}

// 固件升级任务发布 指定设备
export function deploy(tastId, params) {
  return request.axiosIns({
    url: `firmware/upgrade/task/${tastId}/_deploy`,
    method: 'POST',
    data: params,
  })
}

// 固件升级任务发布 全部设备
export function deployAll(tastId) {
  return request.axiosIns({
    url: `firmware/upgrade/task/${tastId}/all/_deploy`,
    method: 'POST',
  })
}

// 查询所有设备的数量
export function getAllDeviceListCount(productd) {
  return request.axiosIns({
    url: `device-instance/_count?terms[0].column=productId&terms[0].value=${productd}`,
    method: 'GET',
  })
}
// 查询任务涉及的设备数量
export function getTaskDevice(firmwareId, taskId) {
  return request.axiosIns({
    url: `firmware/upgrade/history/_query?paging=false&terms%5B0%5D.column=firmwareId&terms%5B0%5D.value=${firmwareId}&terms%5B1%5D.column=taskId&terms%5B1%5D.value=${taskId}`,
    method: 'GET',
  })
}
